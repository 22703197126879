
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import DataTable from '@/components/layout/dataTable/DataTable.vue';
import DataTableRow from '@/components/layout/dataTable/DataTableRow.vue';
import DataTableCell from '@/components/layout/dataTable/DataTableCell.vue';
import Button from '@/components/controls/Button';

export default defineComponent({
    name: 'Home',
    components: {
        Button,
        DataTable,
        DataTableRow,
        DataTableCell,
        Panel,
    },
    mixins: [ViewMixin],
    data: () => ({
    }),
    computed: {
        nukNuk(): string {
            return ',̶̀̾-̵͋̇*̵̛͊.̵̽̒≮͕́~̶̔̓-̸̓̽#1695';
        },
    },
    async created(): Promise<void> {
        this.setPageTitle(['InGame', 'Items']);
    },
});
